import Icon from '@/components/icon'
import Link from '@/components/link'
import { useUserStore } from '@/store/user'
import { getTicketRequest } from '@/apis/common'
import { useLayoutStore } from '@/store/layout'
import { getUUId, handleImCustomer } from '@/helper/common'
import styles from './index.module.css'

function FloatingIconButton() {
  const { customerJumpUrl } = useLayoutStore().layoutProps || {}
  const { userInfo } = useUserStore()

  const onCustomerChange = async () => {
    const params = {
      userId: userInfo?.uid || getUUId?.(),
      nickName: userInfo?.nickName || '*',
      avatar: userInfo?.avatarPath || '*',
      businessId: userInfo?.businessId || '1',
    }
    const res = await getTicketRequest(params)
    if (res?.data?.ticket) {
      window.open(handleImCustomer(res?.data?.ticket))
    }
  }
  return (
    // <Link href={customerJumpUrl || ''} target>
    //   <Icon className={styles.scoped} name="message" />
    // </Link>
    <div onClick={onCustomerChange}>
      <Icon className={styles.scoped} name="message" />
    </div>
  )
}

export default FloatingIconButton
